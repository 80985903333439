import React from "react"
import Logo from "../components/logo"

import Layout from "../components/layout"
import Seo from "../components/seo"
import SplitSection from "../components/SplitSection"

import Header from "../components/header"

import Footer from "../components/footer"

function ContactSubmission() {
  return (
    <>
      <Layout>
        <Seo title="Contact Submission" />
        <div className="relative  back-white  ">
          <Header className="black w-b" />
          <div
            data-scroll-container
            id="scroll-container"
            className="data-scroll-container"
          >
            <div className="flex flex-c p-t-50 ">
              <SplitSection
                classRight="d-b-f "
                wrapperClassName="stack"
                subWrapperClassName="b-b-1-br"
                right={
                  <div className="flex flex-c  flex-s-b h-100-p">
                    <div className="flex-grow-1">
                      <Logo className="f-2A2A2A h-30 m-b-120-br" />
                    </div>
                    <div className="h-100-vh br flex flex-c flex-j-end">
                      <h1 className="c-2A2A2 p-b-40 al-left">Thank You</h1>
                      <h2 className="alt m-w-300">
                        We've received your submission and we'll be in touch
                        shortly.
                      </h2>
                    </div>
                  </div>
                }
              >
                <div className="flex flex-c  flex-s-b h-100-p">
                  <h1 className="c-2A2A2 al-left m-b-120-br">
                    ANYTIME. <br /> ANYWHERE.
                    <br /> YOUR WAY.
                  </h1>
                  <h2 className="alt m-w-500">
                    Our elite travel and event opportunities are limitless. If
                    you are seeking a unique tailored experience, contact us
                    today to plan your next journey
                  </h2>
                </div>
              </SplitSection>
              <Footer />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export default ContactSubmission
